.post-container {
  margin: 0 20% 0 20%;
}

.post-container h2 {
  padding: 10px 0 10px 0;
}

.post-item {
  background-color: white;
  margin: 10px 0 10px 0;

}

.post-item:nth-child(even) {
  background-color: #ccecff;
}

.add-color {
  background-color: #ccecff;
}

.user {
  display: flex;
  padding: 10px;
  align-items: center;;
}

.user img {
  width: 30px;
  border-radius: 50%;
}

.user-name {
  color: Orange;
  margin-left: 5px;
}

.time {
  color: grey;
}

.content {
  display: flex;
  margin: 10px;
}


.content img {
  margin-bottom: 10px;
}



.right-content {
  margin: 10px;
}
